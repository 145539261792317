@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family:"gesta";
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    font-size: 18px;
}
body * {
    font-family:"gesta";
    letter-spacing: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-container {
    padding: 5rem;
}

.gesta{
    font-family:"gesta";
}
.gesta-light{
    font-family:"gesta";
    font-weight: 300 !important;
}
.gesta-regular{
    font-family:"gesta";
    font-weight: 400 !important;
}
.gesta-medium{
    font-family:"gesta";
    font-weight: 500 !important;
}
.gesta-bold{
    font-family:"gesta";
    font-weight: 700 !important;
}

h2 {
    font-size: 35px;
}
h3 {
    font-size: 27px;
}

p {
    font-size: 18px;
    color: #000000;
    font-family:"gesta";
    font-weight: 400;
}

.border-lavenderPurple {
    border-color: #A47EB5;
}
.border-pacificBlue {
    border-color: #09A5B8;
}
.border-cabaret {
    border-color: #DD4E69;
}
.border-blueBell {
    border-color: #8996C5;
}
.border-fireBush {
    border-color: #EA8D34;
}
.border-blueRibbon {
    border-color: #076DE8;
}
.border-ronchi {
    border-color: #E8BD46;
}
.link {
    text-decoration: underline !important;
    cursor: pointer;
}
button[disabled] {
    opacity: 0.4;
    cursor: not-allowed !important;
    pointer-events: unset !important;
}
.disabled {
    opacity: 0.4;
}
.googleButton {
    margin-top: 10px;
}
.googleButton iframe {
    margin: 0 auto !important;
}
/* .googleButton iframe ~ div {
    display: none;
} */
.App-icon, .App-icon svg {
    width: 100px;
    height: 100px;
    fill: red;
}

.icon-cicon, .cicon svg {
    fill: white;
    height: 25px;
    width: 35px;
}
.imgIcon {
    filter: brightness(0) invert(1);
}
.reportTitle {
    font-size: 23px;
    line-height: 1.13;
    width: calc(100% - 44px);
}
.reportDesc {
    font-size: 19px;
    line-height: normal;
}

.icon-takewayicon, .icon-takewayicon svg {
    fill: #ffffff;
    height: 30px;
    width: 35px;
}

textarea,label{
    font-family: 'gesta' !important;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.scaleit__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.challenge_dialog_inner {
    transition: all 1s linear;
}
.rajdhani{
    font-family: 'Rajdhani', sans-serif;
}

.z-99{
    z-index: 99;
}
.z-9999{
    z-index: 9999;
}

.mountain-1,
.mountain-1 svg{
    fill: '#8996C5';
}

/* new css */
.question_parecent_input_parent input, 
.question_parecent_input_parent .MuiInput-root, 
.question_parecent_input_parent input:focus, 
.question_parecent_input_parent select:focus {
    border: none;
    outline: none;
}

.question_parecent_input_parent .MuiInput-root::before {
    display: none;
}

span.question_parecent_input_parent {
    border-bottom: 1px solid #8F8F8F;
    margin-bottom: 1px;
    padding-left: 6px;
}

span.question_parecent_label {
    background: transparent !important;
}
/*  */

@media only screen and (max-width:700px){
    .report_mountainer_container.smallmountain .single_report_mountain .mountain_text {
        display: none !important;
    }
    
    .report_mountainer_container.smallmountain .single_report_mountain:hover .mountain_text {
        display: block !important;
    }
    
}



#sc_preloader{
    height: 100vh;
    width: 100vw;
    background: #fff;
    display: flex;
    align-items:center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
  
  #sc_preloader::after{
    content: "";
    height: 30px;
    width: 30px;
    margin: 40vh auto;
    border: 5px solid rgba(0,0,0,0.05);
    border-top: 5px solid rgb(239,64,80);
    border-radius: 50%;
    animation: scrotate 1s infinite linear;
  
  }
  
  @keyframes scrotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.scrollspy_container li.active a{
    color: #EF4050 !important;
}

.icon-lockicon,
.icon-lockicon svg{
    fill: #EF4050;
    height: 150px !important;
}

@media only screen and (max-width:1024px) {
    .icon-lockicon,
    .icon-lockicon svg{
        height: 70px !important;
    } 
}