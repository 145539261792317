@font-face {
  font-family:"gesta";
  src:url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;
  }
  
  @font-face {
  font-family:"gesta";
  src:url("https://use.typekit.net/af/9c390d/00000000000000007735989d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/9c390d/00000000000000007735989d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/9c390d/00000000000000007735989d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:300;
  }
  
  @font-face {
    font-family:"gesta";
    src:url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
    }
    
    @font-face {
    font-family:"gesta";
    src:url("https://use.typekit.net/af/a93dec/000000000000000000013f55/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/a93dec/000000000000000000013f55/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/a93dec/000000000000000000013f55/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;
    }
  
  @font-face {
  font-family:"gesta";
  src:url("https://use.typekit.net/af/465856/00000000000000007735989f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/465856/00000000000000007735989f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/465856/00000000000000007735989f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;
  }
  
  @font-face {
  font-family:"gesta";
  src:url("https://use.typekit.net/af/e85714/0000000000000000773598a1/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/e85714/0000000000000000773598a1/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/e85714/0000000000000000773598a1/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:500;
  }
  
  @font-face {
  font-family:"gesta";
  src:url("https://use.typekit.net/af/771564/00000000000000000001417c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/771564/00000000000000000001417c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/771564/00000000000000000001417c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;
  }
  
  @font-face {
  font-family:"gesta";
  src:url("https://use.typekit.net/af/628b8b/000000000000000000013f59/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/628b8b/000000000000000000013f59/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/628b8b/000000000000000000013f59/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;
  }
  


body{
    margin: 0;
    padding: 0;
}
*{
  margin: 0;
  padding: 0;
}

.bg-white{
    background-color: #ffffff;
}
h1{
  font-size: 42px;
  line-height: normal;
  font-weight: 500;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}
.d-inline-flex{
  display: inline-flex;
}

.scale_7{
  transform: scale(0.7)
}
.scale_5{
  transform: scale(0.5)
}

.container {
  background: #DFE6ED;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.container .sidebar {
  flex: 0 0 15%;
  max-width: 230px;
  background: rgba(255,255,255,.99);
  height: 100vh;
}

.container .content_area {
  height: 100vh;
  width: calc(100% - 230px);
  max-width: calc(100% - 230px);
}
.sidebar_top {
  text-align: center;
  padding-top: 30px;
}
.content_area__inner {
  padding: 0;
  background-color: #fff;
}
.content_area__top_bar {
  /* padding-bottom: 30px; */
  background-color: #f2f2f1;
}
.content_area__top_bar_right form > div {
  margin-left: 0;
  width: 100%;
}
.sidebar{
  position: relative;
}
.sidebar_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.po-relative{
  position: relative;
}


.MuiPopover-paper ul li.MuiMenuItem-root.Mui-selected,
.MuiPopover-paper ul li.MuiMenuItem-root.Mui-selected:hover {
  background: transparent;
}

.MuiPopover-paper ul {
  padding: 0;
}
.MuiPopover-paper ul li {
  font-family: Gesta;
  font-size: 16px;
  font-weight: 500;
}
#signInDiv {
  position: relative;
  min-height: 44px;
}
#signInDiv iframe {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  right: 0;
  margin: auto !important;
  height: 44px;
}
#container {
  display: block !important;
}

.clientListModal .MuiDialog-paper {
  max-width: 643px;
  margin: 0;
  border-radius: 0;
  display: block;
  box-shadow: none;
}
.homeDashboardModal .MuiDialog-paper {
  max-width: 490px;
  margin: 0;
  border-radius: 0;
  display: block;
  box-shadow: none;
}
.MuiButton-root,
.MuiTableCell-root {
  font-family: gesta !important;
}

/* @media (min-width: 768px) {

  .MuiPopover-root {
    padding-left: 208px;
    max-width: 1108px;
    margin: 0 auto;
  }
  .MuiPopover-paper {
    width: 101px;
    max-height: 151px !important;
    transform: translate(9px, 36px) !important;
    left: auto !important;
    right: 0;
    padding: 7px 0;
    border-radius: 0 !important;
    box-shadow: 0 1px 4px 0 #bdbdbd !important;
  }
  .MuiPopover-paper ul li {
    padding: 7px 13px;
    line-height: 1.25;
  }
  .MuiButton-contained {
    box-shadow: none !important;
  }
}
@media (max-width: 1199px) and (min-width: 768px) {
  
  .MuiPopover-paper {
    transform: translate(-24px, 36px) !important;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .MuiPopover-root{
    max-width: 900px;
    padding-left: 0;
  }
} */
@media (max-width: 767px) {
  
  h1{
    font-size: 30px;
  }
  .MuiPopover-root {
    background: rgba(0, 0, 0, 0.55);
  }
  .MuiPopover-paper {
    left: 19px !important;
    right: 19px !important;
    top: 11px !important;
    bottom: 11px !important;
    width: 100% !important;
    max-height: 100% !important;
    max-width: calc(100% - 40px) !important;
    border-radius: 16px !important;
    transform: translate(0, 0) !important;
  }
  
  .MuiPopover-paper ul li {
    border-bottom: 1px solid #bdbdbd;
    padding: 18px 48px 18px 27px;
    white-space: normal;
  }
  .MuiPopover-paper ul li::after {
    content:"";
    border: 1px solid #828282;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 22px;
    right: 27px;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #828282;
  }
  .MuiPopover-paper ul li::before {
    content:"";
    height: 8px;
    width: 8px;
    position: absolute;
    top: 26px;
    right: 31px;
    border-radius: 50%;
    background: #121e31;
    opacity: 0;
  }
  .MuiPopover-paper ul li.Mui-selected:before {
    opacity: 1;
  }
  .clientListModal .MuiBackdrop-root,
  .homeDashboardModal .MuiBackdrop-root {
    background-color: transparent;
  }
  .clientListModal .MuiDialog-paper,
  .homeDashboardModal .MuiDialog-paper {
    min-height: calc(100% - 60px);
    overflow: auto;
    margin-top: 60px;
  }
  
}